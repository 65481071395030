/* eslint-disable */

import { loginRequest, msalConfig} from "../auth/authConfig.js";
//import { PublicClientApplication, EventType, InteractionStatus } from "@azure/msal-browser";

let msal = await import("@azure/msal-browser");
const msalInstance = new msal.PublicClientApplication(msalConfig);

export async function signIn() {

    await msalInstance.initialize();
    let loginResponse = await msalInstance.loginPopup(loginRequest);
    return await handleResponse(loginResponse);

}

function selectAccount() {

    const currentAccounts = msalInstance.getAllAccounts();
    if (currentAccounts.length === 0) {
        return;
    } else if (currentAccounts.length > 1) {
        console.warn("Multiple accounts detected.");
    }
}

function handleResponse(response) {

    if (response === null) {
        selectAccount();
    } else {
        return response;
    }

}

export async function getTokenSilent(account) {

    loginRequest.account = account;
    return msalInstance.acquireTokenSilent(loginRequest)
        .catch(error => {
            console.log(error);
            console.warn("silent token acquisition fails. acquiring token using popup");
            // fallback to interaction when silent call fails
            return msalInstance.acquireTokenPopup(loginRequest)
                .then(tokenResponse => {
                    return tokenResponse;
                }).catch(error => {
                    console.error(error);
                });
        });
}

export async function logOut() {
    await msalInstance.initialize();
    const logoutRequest = {
        account: msalInstance.getAllAccounts()[0],
        mainWindowRedirectUri: `${window.location.origin}/taskpane.html`,
    };

    await msalInstance.logoutPopup(logoutRequest);
}