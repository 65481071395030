/* eslint-disable */
/* global document, Office */

import { loginRequest, msalConfig} from "../auth/authConfig.js";
import * as authPopup from "../auth/authPopup.js"

let msal = await import("@azure/msal-browser");
let ui = await import("../ui.js");

ui.openLoadingDialog();

const msalInstance = new msal.PublicClientApplication(msalConfig);

const loadingAccount = document.querySelector(".docs-DialogLoadingAccount-blocking");
const dialog = loadingAccount.querySelector(".ms-Dialog");
const dialogComponent = new fabric['Dialog'](dialog);

Office.onReady(async(info) => {

    if (info.host === Office.HostType.Outlook) {
        ui.loadingMainPage();
        let loginBtn = document.getElementById("logInBtn");
        loginBtn.addEventListener("click", async () => {
            signIn(info.platform);
        });

        if (msalInstance.getAllAccounts().length === 1) {
            await msalInstance.initialize();
            loginRequest.account = msalInstance.getAllAccounts()[0];
            let tokenData = await msalInstance.acquireTokenSilent(loginRequest);
            ui.showMainPage(info.platform, tokenData); 
        }

    }

})

async function signIn(platform) {

    if (document.querySelector(".docs-DialogLoadingAccount-blocking") === null) {
        document.body.append(loadingAccount)
    }

    if (platform === Office.PlatformType.PC) {
        msalInstance.initialize().then(async () => {
            if (msalInstance.getAllAccounts().length === 0) {
                window.open(`${window.location.origin}/login.html`, "login", `width=${window.screen.width / 2},height=${window.screen.height}`);
            }
            let id = setInterval(async () => {
                dialogComponent.open();

                if (msalInstance.getAllAccounts().length > 0) {
                    clearInterval(id);
                    dialogComponent.close();
                    loadingAccount.remove();
                    loginRequest.account = msalInstance.getAllAccounts()[0];
                    let tokenData = await msalInstance.acquireTokenSilent(loginRequest);

                    // to do
                    // есть какой-то трабл с acquireTokenSilent, если долго висит в аккаунте
                    // но проявилось только один раз
                    // как-то в трай кэтч пытаться запросить токен, если фэйл, то выйти из кривого акка
                    // и авторизоваться заново                  
                    //let tokenData = await msalInstance.acquireTokenRedirect(loginRequest);
                    
                    ui.showMainPage(platform, tokenData); 
                }
            }, 1000);
        });
    }

    if (platform === Office.PlatformType.OfficeOnline) {
        
        await msalInstance.initialize();
        var tokenData = await authPopup.signIn();
        ui.showMainPage(platform, tokenData);
        
    }

}

