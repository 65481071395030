/* eslint-disable */

export const msalConfig = {
    auth: {
        clientId: "7ce54a40-7e22-4403-9cfb-ed75c6e99efb",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: `${window.location.origin}/login.html`,
        postLogoutRedirectUri: `${window.location.origin}/logout.html`,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
};

export const loginRequest = {
    scopes: ["User.Read", "Mail.ReadWrite", "Sites.ReadWrite.All", "Mail.Send", "Files.ReadWrite.All", "Files.Read.All"],
};

//const msalInstance = new msal.PublicClientApplication(msalConfig);
